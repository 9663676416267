import { Typography, Stack, Box } from "@mui/material";
import React from "react";

const Machine = ({ machine }) => {
  return (
    <Stack
      alignItems="center"
      spacing={2}
      sx={{
        padding: 2,
        borderRadius: 2,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <Box
        sx={{
          aspectRatio: "1 / 1", // Makes the image container square
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f9f9f9",
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        <img
          src={machine.image}
          alt={machine.name}
          width={200}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {machine.name}
      </Typography>
    </Stack>
  );
};

export default Machine;
