import React from "react";

import { AppBar, Box, Grid, Stack, Typography } from "@mui/material";
import "./App.css";
import founderPlaceholder from "./assets/images/gamal-ali.webp";
import K2K2Nextspareparts from "./assets/images/machines/casting/K2 & K2 Next spare parts.jpeg";
import K2_K2NextCastingMachine from "./assets/images/machines/casting/K2_K2 Next Casting Machine.jpeg";
import K3K3UPspareparts from "./assets/images/machines/casting/K3 & K3UP spare parts.jpeg";
import K3_K3UPCastingMachine from "./assets/images/machines/casting/K3_K3UP Casting Machine.jpeg";
import VCCCastingMachine from "./assets/images/machines/casting/VCC Casting Machine.jpeg";
import VCCspareparts from "./assets/images/machines/casting/VCC spare parts.jpeg";
import heatPressMachine from "./assets/images/machines/casting/heat press machine.png";
import muffleFurnace from "./assets/images/machines/casting/muffle furnace.png";
import pressureCasting from "./assets/images/machines/casting/pressure casting.png";
import Machine from "./components/machine";

// Navbar Component
const Navbar = () => {
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#FFD700",
        color: "#000",
        "& a": {
          color: "#000",
          padding: "1rem",
          textDecoration: "none",
          "&:hover": {
            backgroundColor: "#FF6347",
            color: "#fff",
          },
        },
      }}
    >
      <Stack direction={"row"} alignItems={"center"} pl={4} pt={1} pb={2}>
        <Stack direction={"row"} pr={4}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            style={{ width: "32px", height: "32px" }}
          >
            <rect width="32" height="32" fill="#FFD700" />
            <path d="M16 4l6 6h-4v4h-4V10H10l6-6z" fill="#000" />
            <path d="M10 14h4v4h4v-4h4l-6 6-6-6z" fill="#000" />
            <rect x="9" y="24" width="14" height="2" fill="#000" />
          </svg>

          <Typography variant={"h5"}>Mazar USA</Typography>
        </Stack>
        <a href="#home">Home</a>
        <a href="#casting">Casting Machines</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
      </Stack>
    </AppBar>
  );
};

// App Component
const App = () => {
  return (
    <div>
      <Navbar />

      <section id="home">
        <Stack
          alignItems={"center"}
          sx={{
            width: "100%",
            paddingTop: "5rem",
          }}
        >
          <Typography variant="h4">Welcome to Mazar USA</Typography>
          <Box sx={{ padding: "0 32px 0 32px", width: "75%" }}>
            Mazar USA is a leading provider of gold-making tools and supplies to
            manufacturers around the world, especially in Egypt. We offer a wide
            range of machinery that transforms gold into beautiful chains, along
            with brushes, polishing tools, and more.
          </Box>
          <Machine
            machine={{
              name: "K3_K3UP Casting Machine",
              image: K3_K3UPCastingMachine,
            }}
          />
        </Stack>
      </section>

      {/* Products Section */}
      <section id="casting" className="section">
        <Box className="container" pt={4}>
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
            }}
          >
            Casting Machines
          </Typography>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-evenly"
          >
            <Grid item>
              <Machine
                machine={{
                  name: "K2 & K2 Next spare parts",
                  image: K2K2Nextspareparts,
                }}
              />
            </Grid>
            <Grid item>
              <Machine
                machine={{
                  name: "K2_K2 Next Casting Machine",
                  image: K2_K2NextCastingMachine,
                }}
              />
            </Grid>
            <Grid item>
              <Machine
                machine={{
                  name: "K3 & K3UP spare parts",
                  image: K3K3UPspareparts,
                }}
              />
            </Grid>
            <Grid item>
              <Machine
                machine={{
                  name: "VCC Casting Machine",
                  image: VCCCastingMachine,
                }}
              />
            </Grid>
            <Grid item>
              <Machine
                machine={{ name: "VCC spare parts", image: VCCspareparts }}
              />
            </Grid>
            <Grid item>
              <Machine
                machine={{ name: "Heat Press Machine", image: heatPressMachine }}
              />
            </Grid>
            <Grid item>
              <Machine
                machine={{ name: "Muffle Furnace", image: muffleFurnace }}
              />
            </Grid>
            <Grid item>
              <Machine
                machine={{ name: "Pressure Casting", image: pressureCasting }}
              />
            </Grid>
          </Grid>
        </Box>
      </section>

      {/* About Section */}
      <section id="about" className="section">
        <Box className="container" pt={4}>
          <Typography variant="h4">About Mazar USA</Typography>
          <p>
            Mazar USA was founded by <strong>Gamal Ali</strong>, a passionate
            businessman born in Egypt, who was fascinated by the art of gold
            manufacturing in the historic city of Hussien, Cairo. His love for
            the craft led him to establish Mazar USA, which now supplies
            gold-making tools to manufacturers worldwide.
          </p>
          <img
            src={founderPlaceholder}
            alt="Founder Gamal Ali"
            className="founder-photo"
          />
        </Box>
      </section>

      {/* Contact Section */}
      <section id="contact" className="section">
        <Box className="container" pt={4}>
          <Typography variant="h4">Contact Us</Typography>
          <p>
            Phone: <a href="tel:+17816402206">+1 (781) 640-2206</a>
          </p>
          <p>
            Email: <a href="mailto:mazarusa@aol.com">mazarusa@aol.com</a>
          </p>
        </Box>
      </section>
    </div>
  );
};

export default App;
